import React, { useState } from "react"
import { useRouter} from "next/router"
import { Button, FormGroup, H3, InputGroup, Intent } from "@blueprintjs/core"
import Container from "../../common/ui/container"
import { ToasterContext } from "../../common/ui/toaster"
import { withContext } from "../../common/framework/context"
import { preventDefault } from "../../common/ui/util"

const ResetPasswordEmailForm = ({ onSubmit }) => {
  const [email, setEmail] = useState({})
  const setUserEmail = (evt) => setEmail(evt.target.value)

  return (
    <form
      className="reset-password-form"
      onSubmit={preventDefault(() => onSubmit({ email }))}
    >
      <FormGroup label="Email">
        <InputGroup name="email" type="email" onChange={setUserEmail} />
      </FormGroup>
      <Button intent="primary" type="submit">
        Send Reset Password Email
      </Button>
    </form>
  )
}

export const SuccessEmailPanel = withContext(
  ToasterContext, "toaster"
)(({ toaster }) => {
  const router = useRouter()
  toaster.show({
    message: "Email sent successfully!",
    intent: Intent.SUCCESS,
    icon: "tick-circle",
  })
  router.push("/auth/login")
  return null
})

export const ResetPassword = ({
  onUserResetPassword,
  accessTokens,
  successfulReset,
}) => {
  const router = useRouter()
  if (accessTokens.access) {
    const params = new URLSearchParams(window.location.search)
    const next = params.get("next", "/")
    router.replace(next)
    return null
  }

  if (successfulReset == null) {
    return (
      <div className="content-panel reset-password">
        <Container>
          <H3>Reset Password</H3>
          <ResetPasswordEmailForm onSubmit={onUserResetPassword} />
        </Container>
      </div>
    )
  }

  return <SuccessEmailPanel />
}
