import { compose } from "lodash/fp"
import { connect } from "react-redux"
import { ApiContext } from "../../common/framework/api-context"
import { withContext } from "../../common/framework/context"
import { LoginUser } from "../use-cases/login-user"
import { LoginPanel } from "./login-panel"

const mapStateToProps = (state) => ({
  isLoggedIn: Boolean(state.login.accessTokens.access)
})

const mapDispatchToProps = (dispatch, { api }) => ({
  onUserLogin: LoginUser(api.loginUser, dispatch),
})

export const LoginPanelContainer = compose(
  withContext(ApiContext, "api"),
  connect(mapStateToProps, mapDispatchToProps)
)(LoginPanel)

export default LoginPanelContainer
