import { User } from "../domain/user"
import { actions } from "../framework/actions"

export const ActivateUser = (apiActiveUser, dispatch) => async ({ code }) => {
  dispatch(actions.doRegisterFailed({ error: null }))

  const response = await apiActiveUser({ code })

  const resultAction = response.error
    ? actions.doRegisterFailed({ error: response })
    : actions.doRegister({ user: new User(response) })

  return dispatch(resultAction)
}

export default ActivateUser
