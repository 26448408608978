import { actions } from "../framework/actions"

export const newPasswordUser =
  (apiNewUserPassword, dispatch) => async (request) => {
    const response = await apiNewUserPassword(request)

    const resultAction = response.error
      ? actions.doPasswordResetFailed({ error: response })
      : actions.doPasswordReset(response)
    return dispatch(resultAction)
  }

export default newPasswordUser
