import { compose } from "lodash/fp"
import { connect } from "react-redux"
import { ApiContext } from "../../common/framework/api-context"
import { withContext } from "../../common/framework/context"
import { newPasswordUser } from "../use-cases/new-password-user"
import { NewPassword } from "./reset-password-screen"

const mapStateToProps = (state) => ({
  accessTokens: state.login.accessTokens,
  error: state.login.error,
  successfulReset: state.login.successfulReset,
})

const mapDispatchToProps = (dispatch, { api }) => ({
  onUserNewPassword: newPasswordUser(api.newUserPassword, dispatch),
})

export const NewPasswordContainer = compose(
  withContext(ApiContext, "api"),
  connect(mapStateToProps, mapDispatchToProps)
)(NewPassword)

export default NewPasswordContainer
