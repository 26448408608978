import React, { useState } from "react"
import { useRouter } from "next/router"
import { Button, FormGroup, H3, InputGroup, Intent } from "@blueprintjs/core"
import Container from "../../common/ui/container"
import { preventDefault } from "../../common/ui/util"
import { ToasterContext } from "../../common/ui/toaster"
import { withContext } from "../../common/framework/context"

const withToasterContext = withContext(ToasterContext, "toaster")

const ResetPasswordForm = withToasterContext(({ onSubmit, resetToken, error, toaster }) => {
  const [firstPassword, setFirstPasswords] = useState()
  const [secondPassword, setSecondPasswords] = useState()

  const validateAndSubmitPassword = () => {
    if (firstPassword !== secondPassword) {
      return toaster.show({
        message: "Passwords Mismatch!",
        intent: "warning",
        icon: "warning-sign",
      })
    }

    return onSubmit({ token: resetToken, password: firstPassword })
  }

  return (
    <form
      id="reset-form"
      className="new-password-form"
      onSubmit={preventDefault(validateAndSubmitPassword)}
    >
      <FormGroup
        label="New Password"
        intent={error && error.password && "danger"}
        helperText={error && error.password}
      >
        <InputGroup
          name="password"
          type="password"
          form="reset-form"
          intent={error && error.password && "danger"}
          onChange={(e) => setFirstPasswords(e.target.value)}
        />
      </FormGroup>
      <FormGroup label="Confirm New Password">
        <InputGroup
          name="password2"
          type="password"
          form="reset-form"
          onChange={(e) => setSecondPasswords(e.target.value)}
        />
      </FormGroup>

      <Button intent="primary" type="submit">
        Confirm
      </Button>
    </form>
  )
})

export const SuccessResetPanel = withToasterContext(() => {
  const router = useRouter()
  toaster.show({
    message: "Your password was reset. Now you can login.",
    intent: Intent.SUCCESS,
    icon: "tick-circle",
  })
  router.push("/auth/login")
})

export const NewPassword = ({
  error, 
  onUserNewPassword, 
  accessTokens, 
  successfulReset,
  invalidToken,
}) => {
  const router = useRouter()
  const resetToken = router.query.token
  if (accessTokens.access) {
    const next = router.query.next
    router.replace(next != null ? next : "/auth/login")
  }

  if (successfulReset == null) {
    return (
      <div className="content-panel new-password">
        <Container>
          <H3>Set New Password</H3>
          <ResetPasswordForm
            onSubmit={(submission) => onUserNewPassword({ ...submission, token: resetToken })}
            error={error}
          />
        </Container>
      </div>
    )
  }
  
  return <SuccessResetPanel />
}
