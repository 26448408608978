import React, { useEffect, useState } from "react"
import Link from "next/link"
import { useRouter } from "next/router"
import { Button, FormGroup, H3, InputGroup, Intent } from "@blueprintjs/core"
import { Container, Inset } from "../../common/ui/container"
import { ToasterContext } from "../../common/ui/toaster"
import { withContext } from "../../common/framework/context"
import { preventDefault } from "../../common/ui/util"

const RegisterForm = ({
  onSubmit,
  isLoading,
  overrideEmail,
  invitationCode,
  error,
}) => {
  const [fields, setFields] = useState({
    email: "",
    password: "",
    password2: "",
    first_name: "",
    last_name: "",
    invitation_code: invitationCode,
  })
  const setField = (evt) =>
    setFields({
      ...fields,
      [evt.target.name]: evt.target.value,
    })

  useEffect(() => {
    setFields((fields) => ({ ...fields, email: overrideEmail }))
  }, [overrideEmail])

  return (
    <form
      className="register-form"
      onSubmit={preventDefault(() => onSubmit(fields))}
    >
      <FormGroup
        label="Email"
        intent={error && error.email && "danger"}
        helperText={error && error.email}
      >
        <InputGroup
          name="email"
          type="email"
          intent={error && error.email && "danger"}
          value={fields.email}
          disabled={Boolean(overrideEmail)}
          onChange={setField}
          autoComplete="username"
        />
      </FormGroup>
      <FormGroup
        label="Password"
        intent={error && error.password && "danger"}
        helperText={error && error.password}
      >
        <InputGroup
          name="password"
          type="password"
          intent={error && error.password && "danger"}
          value={fields.password}
          onChange={setField}
          autoComplete="new-password"
        />
      </FormGroup>
      <FormGroup
        label="Confirm Password"
        intent={error && error.password2 && "danger"}
        helperText={error && error.password2}
      >
        <InputGroup
          name="password2"
          type="password"
          intent={error && error.password2 && "danger"}
          value={fields.password2}
          onChange={setField}
          autoComplete="new-password"
        />
      </FormGroup>
      <FormGroup
        label="First Name"
        intent={error && error.first_name && "danger"}
        helperText={error && error.first_name}
      >
        <InputGroup
          name="first_name"
          type="text"
          intent={error && error.first_name && "danger"}
          value={fields.first_name}
          onChange={setField}
          autoComplete="given-name"
        />
      </FormGroup>
      <FormGroup
        label="Last Name"
        intent={error && error.last_name && "danger"}
        helperText={error && error.last_name}
      >
        <InputGroup
          name="last_name"
          type="text"
          intent={error && error.last_name && "danger"}
          value={fields.last_name}
          onChange={setField}
          autoComplete="family-name"
        />
      </FormGroup>
      <InputGroup
        type="hidden"
        name="code"
        value={invitationCode}
        disabled={isLoading}
      />
      <Button intent="primary" fill type="submit">
        Register
      </Button>
    </form>
  )
}

const FormPanel = ({ onSubmit, getInvitation, error }) => {
  const [existingInvite, setExistingInvite] = useState({
    email: "",
    loading: false,
  })
  
  const router = useRouter()
  const invitationCode = router.query.code
  useEffect(() => {

    if (invitationCode == null) {
      return
    }

    setExistingInvite({ email: "", loading: true })
    getInvitation({ invitationCode }).then((user) => {
      setExistingInvite({ email: user.email, loading: false })
    })
  }, [getInvitation])

  return (
    <Container>
      <Inset>
        <H3>Register</H3>
        <RegisterForm
          onSubmit={onSubmit}
          isLoading={existingInvite.loading}
          overrideEmail={existingInvite.email}
          invitationCode={invitationCode}
          error={error}
        />
        <div className="mt-2">
          <p>
            Already have an account? <Link href="/auth/login/">Login</Link>
          </p>
          <p>
            Didn't get confirmation email?{" "}
            <Link href="/auth/send-email-confirm/">Resend Email</Link>
          </p>
        </div>
      </Inset>
    </Container>
  )
}

export const SuccessPanel = withContext(ToasterContext, "toaster")(({ toaster }) => {
  const router = useRouter()
  toaster.show({
    message: "Your registration was sucessful. Now you can login.",
    intent: Intent.SUCCESS,
    icon: "tick-circle",
  })
  router.push("/")
  return null
})

export const EmailSentPanel = () => (
  <Container>
    <div>Please check your email to complete your registration.</div>
  </Container>
)

export const RegisterPanel = ({
  registeredUser: user,
  onUserRegister,
  getInvitation,
  error,
}) => {
  if (user == null) {
    return (
      <FormPanel
        onSubmit={onUserRegister}
        getInvitation={getInvitation}
        error={error}
      />
    )
  }
  return user.isActive ? <SuccessPanel /> : <EmailSentPanel />
}
