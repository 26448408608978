import React, { useState, useEffect } from "react"
import Link from "next/link"
import { useRouter } from "next/router"
import { Button, FormGroup, H3, InputGroup } from "@blueprintjs/core"
import { Container, Inset } from "../../common/ui/container"
import { preventDefault } from "../../common/ui/util"

const LoginForm = ({ onSubmit }) => {
  const [fields, setFields] = useState({})
  const setField = (evt) =>
    setFields({
      ...fields,
      [evt.target.name]: evt.target.value,
    })

  return (
    <form
      className="login-form"
      onSubmit={preventDefault(() => onSubmit(fields))}
    >
      <FormGroup label="Email">
        <InputGroup
          name="email"
          type="email"
          value={fields.email}
          onChange={setField}
          autoComplete="username"
        />
      </FormGroup>
      <FormGroup label="Password">
        <InputGroup
          name="password"
          type="password"
          value={fields.password}
          onChange={setField}
          autoComplete="current-password"
        />
      </FormGroup>
      <Button intent="primary" fill type="submit">
        Log In
      </Button>
    </form>
  )
}

export const LoginPanel = ({ onUserLogin, isLoggedIn }) => {
  const router = useRouter()
  useEffect(() => {
    if (isLoggedIn) {
      const next = router.query.next
      router.replace(next != null ? decodeURIComponent(next) : "/plans")
    }
  }, [isLoggedIn])
  

  return (
    <Container>
      <Inset>
        <H3>Login</H3>
        <LoginForm onSubmit={onUserLogin} />
        <div className="mt-2">
          <p>
            Don't have an account yet?{" "}
            <Link href="/auth/register/">Register</Link>
          </p>
        </div>
        <div>
          <p>
            Forgot Password? <Link href="/auth/forgot-password/">Reset Password</Link>
          </p>
        </div>
      </Inset>

    </Container>
  )
}
