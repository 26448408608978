import { connect } from "react-redux"
import { withRouter } from "next/router"
import { useEffect } from "react"

const loginRequired = ({ children, isHydrated, isLoggedIn, router }) => {
  useEffect(() => {
    if (isHydrated && !isLoggedIn) {
      router.replace({
        pathname: `/auth/login`, query: {
          next: router.pathname ? encodeURIComponent(router.pathname) : null
        }
      })
    }
  }, [isHydrated, isLoggedIn])
  
  return children 
}

const mapStateToProps = (state) => ({
  isHydrated: state.login.hydrated,
  isLoggedIn: Boolean(state.login.accessTokens.access)
})

export const LoginRequired = withRouter(connect(mapStateToProps)(loginRequired))
export default LoginRequired
