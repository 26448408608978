import { AccessTokens } from "../domain/access-tokens"
import { actions } from "../framework/actions"

export const LoginUser =
  (apiLoginUser, dispatch) =>
  async ({ email, password }) => {
    const response = await apiLoginUser({ email, password })

    const resultAction = response
      ? actions.doLogin(new AccessTokens(response).toJSON())
      : actions.doLoginFailed({ error: "Log in failed!" })

    return dispatch(resultAction)
  }

export default LoginUser
