import { compose } from "lodash/fp"
import { connect } from "react-redux"
import { ApiContext } from "../../common/framework/api-context"
import { withContext } from "../../common/framework/context"
import { RegisterUser } from "../use-cases/register-user"
import { RegisterPanel } from "./register-panel"

const mapStateToProps = (state) => ({
  error: state.login.registerError,
  registeredUser: state.login.registeredUser,
})

const mapDispatchToProps = (dispatch, { api }) => ({
  onUserRegister: RegisterUser(api.registerUser, dispatch),
  getInvitation: api.getUserInvitation,
})

export const RegisterPanelContainer = compose(
  withContext(ApiContext, "api"),
  connect(mapStateToProps, mapDispatchToProps)
)(RegisterPanel)

export default RegisterPanelContainer
