import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useRouter } from "next/router"
import { Intent } from "@blueprintjs/core"
import { ActivateUser } from "../use-cases/activate-user"
import { compose } from "lodash/fp"
import { ApiContext } from "../../common/framework/api-context"
import { ToasterContext } from "../../common/ui/toaster"
import { withContext } from "../../common/framework/context"

const UserActivator = ({ registeredUser, error, onActivateUser, toaster }) => {
  const router = useRouter()
  const code = router.query.code
  useEffect(() => {
    if (code !== null) {
      onActivateUser({ code })
    }
  }, [code, onActivateUser])

  if (code === null) {
    router.replace("/auth/login")
  }

  if (registeredUser != null) {
    toaster.show({
      message: "Your registration is sucessfull. Now you can login.",
      intent: Intent.SUCCESS,
      icon: "tick-circle",
    })
    router.replace("/auth/login")
  }

  if (error) {
    toaster.show({
      message: "Error occured during activation. Try again later.",
      intent: Intent.WARNING,
      icon: "warning-sign",
    })
    router.replace("/auth/login")
  }

  return <div>Activating your account</div>
}

const mapStateToProps = (state) => ({
  error: state.login.registerError,
  registeredUser: state.login.registeredUser,
})

const mapDispatchToProps = (dispatch, { api }) => ({
  onActivateUser: ActivateUser(api.activateUser, dispatch),
})

export const ActivateUserContainer = compose(
  withContext(ApiContext, "api"),
  withContext(ToasterContext, "toaster"),
  connect(mapStateToProps, mapDispatchToProps)
)(UserActivator)

export default ActivateUserContainer
