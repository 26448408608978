import { actions } from "../framework/actions"

export const ResetPassword =
  (apiResetPassword, dispatch) =>
  async ({ email }) => {
    const response = await apiResetPassword(email)
    const resultAction = response.error
      ? actions.doPasswordResetFailed({ error: response })
      : actions.doPasswordReset(response)
    return dispatch(resultAction)
  }

export default ResetPassword
