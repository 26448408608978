import { compose } from "lodash/fp"
import { connect } from "react-redux"
import { withContext } from "../../common/framework/context"
import { ResetPassword } from "../use-cases/reset-password"
import { ResetPassword as RestPasswordForm } from "./reset-password-email"
import { ApiContext } from "../../common/framework/api-context"

const mapStateToProps = (state) => ({
  accessTokens: state.login.accessTokens,
  error: state.login.error,
  successfulReset: state.login.successfulReset,
})

const mapDispatchToProps = (dispatch, { api }) => ({
  onUserResetPassword: ResetPassword(api.resetUserPassword, dispatch),
})

export const ResetPasswordContainer = compose(
  withContext(ApiContext, "api"),
  connect(mapStateToProps, mapDispatchToProps)
)(RestPasswordForm)

export default ResetPasswordContainer
