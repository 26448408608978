import { User } from "../domain/user"
import { actions } from "../framework/actions"

export const RegisterUser = (apiRegisterUser, dispatch) => async (request) => {
  dispatch(actions.doRegisterFailed({ error: {} }))

  if (!Boolean(request.invitation_code)) {
    delete request.invitation_code
  }

  const response = await apiRegisterUser({ userData: request })

  if (response.error) {
    const resultAction = actions.doRegisterFailed({ error: response })
    return dispatch(resultAction)
  }

  if (typeof window !== "undefined" && window.sa_event != null) {
    window.sa_event('register');
  }
  const resultAction = actions.doRegister({ user: new User(response) })
  return dispatch(resultAction)
}

export default RegisterUser
